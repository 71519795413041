import {sections} from './query-sections';
import {subsections} from './query-subsections';
import {deeplink_sections} from './query-deeplink-sections';
import {link_types} from './query-link-types';

export const error_query_params = [
  `${sections.PROFILE}.${subsections.SMS_OPT_IN_VIA_EMAIL}`,
  `${sections.HEALTH}.${subsections.MEDICATIONS}`,
  `${sections.PQRS_SELF_REPORT}`,
  `${sections.PQRS_SELF_REPORT}.${subsections.VERIFICATION}`,
  `${sections.SELF_PAY_QUICK_REGISTRATION}`,
  `${sections.PAYMENT}`
];

export const coming_soon_query_params = [
  `${sections.HEALTH}`,
  `${sections.HEALTH}.${subsections.CARE_SUMMARIES}`
];

export const appointments_query_params = [
  `${sections.APPOINTMENTS}`,
  `${sections.APPOINTMENTS}.${subsections.INVITE_LINK}`,
  `${sections.LANDING}`,
  `${sections.LANDING}.${subsections.INVITE_LINK}`,
  `${sections.LANDING}.${subsections.INVITE_LINK}.${deeplink_sections.APPOINTMENTS}.${link_types.APPOINTMENT_EDIT_1}`,
  `${sections.LANDING}.${subsections.INVITE_LINK}.${deeplink_sections.APPOINTMENTS}.${link_types.APPOINTMENT_EDIT_2}`,
  `${sections.LANDING}.${subsections.INVITE_LINK}.${deeplink_sections.APPOINTMENTS}.${link_types.APPOINTMENT_URGENT_CANCELLATION}`,
  `${sections.LANDING}.${subsections.INVITE_LINK}.${deeplink_sections.APPOINTMENTS}.${link_types.APPOINTMENT_WEATHER_CANCELLATION}` ,
  `${sections.LANDING}.${subsections.INVITE_LINK}.${deeplink_sections.APPOINTMENTS}.${link_types.APPOINTMENT_NO_SHOW}`
];

export const appointments_confirmed_query_params = [
  `${sections.APPOINTMENTS}.${subsections.APPOINTMENT_CONFIRMATION_VIA_EMAIL}`,
  `${sections.LANDING}.${subsections.INVITE_LINK}.${deeplink_sections.APPOINTMENTS}.${link_types.APPOINTMENT_CONFIRMATION_1}`,
  `${sections.LANDING}.${subsections.INVITE_LINK}.${deeplink_sections.APPOINTMENTS}.${link_types.APPOINTMENT_CONFIRMATION_2}`,
  `${sections.APPOINTMENTS}.${subsections.APPOINTMENT_CONFIRMATION_VIA_EMAIL}.${link_types.APPOINTMENT_CONFIRMATION_2}`
];

export const profile_query_params = [`${sections.PROFILE}`];

export const login_query_params = [`${sections.PROFILE}.${subsections.PREFERENCES}`];

export const messages_query_params = [
  `${sections.MESSAGES}`,
  `${sections.LANDING}.${subsections.INVITE_LINK}.${deeplink_sections.MESSAGES}.${link_types.MESSAGE_NOTICE}`
];

export const register_query_params = [`${sections.WELCOME}`];

export const athena_portal_query_params = [
  `${sections.PASSWORD}`,
  `${sections.PASSWORD}.${subsections.PASSWORD_RESET_LINK}`];
