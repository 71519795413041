import React from 'react';
import Navbar from "react-bootstrap/Navbar";

import lightIcon from './static/images/AWC_Brand_light.svg';
import darkIcon from './static/images/AWC_Brand_dark.svg';
import Routes from './Routes';
import {BrowserRouter, Link} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar bg="white" expand="lg" className={'WellnessNav align-items-start'}>
          <Navbar.Brand>
            <Link to="/" role="heading" aria-level="1">
              <img src={lightIcon} className={'wellness-icon dark-mode-only'} alt={'Apple Wellness Center'}/>
              <img src={darkIcon} className={'wellness-icon light-mode-only'} alt={'Apple Wellness Center'}/>
            </Link>
          </Navbar.Brand>
        </Navbar>
        <Routes/>
      </div>
    </BrowserRouter>
  );
}

export default App;
