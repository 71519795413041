export const subsections = {
  PREFERENCES: 'preferences',
  SMS_OPT_IN_VIA_EMAIL: 'smsoptinviaemail',
  APPOINTMENT_CONFIRMATION_VIA_EMAIL: 'apptconfirmationviaemail',
  INVITE_LINK: 'invite_link',
  CARE_SUMMARIES: 'care_summaries',
  MEDICATIONS: 'medications',
  VERIFICATION: 'verification',
  PASSWORD_RESET_LINK: 'password_reset_link'
};
