import queryString from 'query-string';
import {LinkMap} from './LinkMap';

export class RedirectLink {
  constructor(link) {
    const query_params = queryString.parse(link);

    this.section = query_params.section;
    this.subsection = query_params.sub;

    if (query_params.deeplink) {
      const deeplink = query_params.deeplink.toString();
      const deeplink_decoded = decodeURIComponent(deeplink);
      const deeplink_query_params = queryString.parse(deeplink_decoded);

      this.deeplink_section = deeplink_query_params.section;
      this.deeplink_link_type = deeplink_query_params.athenareferrer;
    }

    this.link_map = new LinkMap();

    this.key = '';
    this.deeplink_key = '';
    this.basic_key = '';
    this._build_key();
  }

  _build_key() {
    this._build_basic_key();
    this._build_deeplink_key();

    this.key = this.basic_key;

    if (this.deeplink_key) {
      this.key +=  '.' + this.deeplink_key;
    }
  }

  _build_basic_key() {
    if (this.section) {
      this.basic_key += this.section;
    }
    if (this.subsection) {
      this.basic_key += `.${this.subsection}`;
    }
  }

  _build_deeplink_key() {
    if (this.deeplink_section) {
      this.deeplink_key += `${this.deeplink_section}`;
    }
    if (this.deeplink_link_type) {
      this.deeplink_key += `.${this.deeplink_link_type}`;
    }
  }

  get_portal_path() {
    if (this.link_map.contains(this.key)) {
      return this.link_map.get(this.key);
    } else if (this.link_map.contains(this.basic_key)) {
      return this.link_map.get(this.basic_key);
    } else {
      return this.link_map.get('404');
    }
  }
}
