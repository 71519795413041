export const link_types = {
  APPOINTMENT_CONFIRMATION_1: 'REMINDERCALL_CONFIRMATION_CTA',
  APPOINTMENT_CONFIRMATION_2: 'REMINDERCALL_STANDARD_CTA',
  APPOINTMENT_EDIT_1: 'REMINDERCALL_CONFIRMATION_SECONDARY',
  APPOINTMENT_EDIT_2: 'REMINDERCALL_STANDARD_SECONDARY',
  APPOINTMENT_WEATHER_CANCELLATION: 'GROUPCALL_WEATHER_CTA',
  APPOINTMENT_URGENT_CANCELLATION: 'GROUPCALL_URGENTCANCELLATION_CTA',
  APPOINTMENT_NO_SHOW: 'GROUPCALL_NOSHOW_CTA',
  MESSAGE_NOTICE: 'WEBPORTAL_GENERALMESSAGE_CTA'
};
