export const sections = {
  PROFILE: 'profile',
  APPOINTMENTS: 'appointments',
  MESSAGES: 'messages',
  HEALTH: 'health',
  PQRS_SELF_REPORT: 'pqrsselfreport',
  SELF_PAY_QUICK_REGISTRATION: 'selfpay_quickreq',
  WELCOME: 'welcome',
  PASSWORD: 'password',
  PAYMENT: 'payment',
  LANDING: 'landing'
};
