import React from 'react';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import {US_LOCATIONS, INTERNATIONAL_LOCATIONS} from './const/locations';

export default class LocationSelect extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      location_url: '',
      dropdown_title: 'Select Location',
      submitted: false
    };
  }

  handleChange(e) {
    if (e) {
      e.preventDefault();

      const {value} = e.target;

      if (value) {
        this.setState({location_url: value});
      }
    }
  }

  handleSelect(eventKey, e) {
    if (e) {
      e.preventDefault();

      const {href, text} = e.target;

      if (href) {
        this.setState({location_url: href, dropdown_title: text});
      }
    }
  }

  handleSubmit() {
    this.setState({submitted: true});

    if (this.state.location_url !== '') {
      window.location.href = this.state.location_url;
    }
  }

  render() {
    return (
      <div className={'container d-flex justify-content-center align-items-center WellnessBody text-center'}>
        <div className={'form-group'}>
          <DropdownButton id={'locationSelect'} title={this.state.dropdown_title} variant={'light'} size={'lg'} className={'location-dropdown'}>
            <Dropdown.Header>United States</Dropdown.Header>
            {
              Object.keys(US_LOCATIONS).map((key, index) => (
                <Dropdown.Item key={index} href={US_LOCATIONS[key]} onSelect={this.handleSelect}>{key}</Dropdown.Item>
              ))
            }
            <Dropdown.Divider/>
            <Dropdown.Header>International</Dropdown.Header>
            {
              Object.keys(INTERNATIONAL_LOCATIONS).map((key, index) => (
                <Dropdown.Item key={index} href={INTERNATIONAL_LOCATIONS[key]} onSelect={this.handleSelect}>{key}</Dropdown.Item>
              ))
            }
          </DropdownButton>
        </div>
        <Button type="submit" variant={'link'} className={'chevron right'} onClick={this.handleSubmit} disabled={!this.state.location_url}>Continue</Button>
      </div>
    );
  }
}
