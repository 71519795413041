import React from 'react';
import {RedirectLink} from './RedirectLink';
import {Link} from 'react-router-dom';

export default class Redirect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      portal_path: ''
    };
  }

  componentDidMount() {
    const redirect_link = new RedirectLink(this.props.location.search);
    let portal_path = redirect_link.get_portal_path();

    if (portal_path)
    {
      this.setState({portal_path: portal_path});
      window.location.href = portal_path;
    }
  }

  render() {
    const {portal_path} = this.state;

    return (
      <div>
        <div className="h2 text-center">Please wait while you are redirected.</div>
        <div className="h6 text-center p-t-1">If you are not automatically redirected, please click <Link to={portal_path}>here</Link>.</div>
      </div>
    );
  }
}
