export const PATIENT_PORTAL = "https://scv.apple-wellnesscenter.com";

export const US_LOCATIONS = {
  "Austin": "https://austin.apple-wellnesscenter.com",
  "Sacramento": "https://sacramento.apple-wellnesscenter.com",
  "Cupertino/SCV": PATIENT_PORTAL,
  "Cupertino/SCV Legacy Portal": "https://scvx.apple-wellnesscenter.com",
  "On Demand Care - National": PATIENT_PORTAL
};

export const INTERNATIONAL_LOCATIONS = {
  "Ireland": "https://cork.apple-wellnesscenter.com/home",
  "London": "https://patientbooking.co.uk/uk-apple-wellnesscentre/#/login",
  "Singapore": "https://singapore.apple-wellnesscenter.com"
};
