import React from 'react';

export default class NotFoundPage extends React.Component {
  render() {

    return (
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h1>
              Page Not Found
            </h1>
            <p className="h5 p-t-1">
              The page you are trying to access could not be found.
            </p>
            <p className="h5">
              If you believe you have arrived at this page in error, contact the Wellness Clinic.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
