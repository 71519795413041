import {PATIENT_PORTAL} from './const/locations';
import {error_query_params, coming_soon_query_params, appointments_query_params, appointments_confirmed_query_params, profile_query_params, login_query_params, messages_query_params, register_query_params, athena_portal_query_params} from './const/query-param-mappings';

export class LinkMap {
  constructor() {
    this.param_path_mapping = {};

    this.add(PATIENT_PORTAL, '/?section=error', error_query_params);
    this.add(PATIENT_PORTAL, '/?section=comingsoon', coming_soon_query_params);
    this.add(PATIENT_PORTAL, '/appointments', appointments_query_params);
    this.add(PATIENT_PORTAL, '/appointments/confirmed', appointments_confirmed_query_params);
    this.add(PATIENT_PORTAL, '/profile', profile_query_params);
    this.add(PATIENT_PORTAL, '/login', login_query_params);
    this.add(PATIENT_PORTAL, '/messages', messages_query_params);
    this.add(PATIENT_PORTAL, '/register', register_query_params);
    this.add(`${window.location.protocol}//${window.location.host}`, '/404', ['404']);
    this.add("https://19661-1.portal.athenahealth.com/", '', athena_portal_query_params);
  }

  add(hostname, path, link_params) {
    for (let i = 0; i < link_params.length; i++) {
      const link_param = link_params[i];
      this.param_path_mapping[link_param] = `${hostname}${path}`;
    }
  }

  get(key) {
    return this.param_path_mapping[key];
  }

  contains(key) {
    return key in this.param_path_mapping;
  }
}

