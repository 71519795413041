import {Switch, Route} from 'react-router-dom';
import LocationSelect from './LocationSelect';
import React from 'react';
import Redirect from './Redirect';
import NotFoundPage from './NotFoundPage';

export default function Routes() {
  return (
    <Switch>
      <Route path="/19661/1" component={Redirect}/>
      <Route path="/404" component={NotFoundPage}/>
      <Route component={LocationSelect}/>
    </Switch>
  );
}
